<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Einstellungen</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Einstellungen</ion-title>
        </ion-toolbar>
      </ion-header>
<!--      <ion-list>
	<p>TEST: {{suchmodus}} :END"</p>
    <ion-radio-group v-model="suchmodus" mode="ios">
      <ion-list-header>
        <ion-label>SUCHMODUS</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label>Wortanfang</ion-label>
        <ion-radio slot="start" value="wortanfang"  @click="() => setOptions('suchmodus','wortanfang')"></ion-radio>
      </ion-item>
 <ion-item>
        <ion-label>exaktes Wort</ion-label>
        <ion-radio slot="start" value="exakteswort" @click="() => setOptions('suchmodus','exakteswort')"></ion-radio>
 </ion-item>
  <ion-item>
        <ion-label>Teilstring</ion-label>
        <ion-radio slot="start" value="teilstring"  @click="() => setOptions('suchmodus','teilstring')"></ion-radio>
  </ion-item>
   </ion-radio-group>
      </ion-list>
   -->   
     

<!--      <ion-list mode="ios">
	<ion-item>
   <ion-label>KONJUGIERTE VERBEN</ion-label>

  <ion-toggle   value="default" >  </ion-toggle>
  </ion-item>
      </ion-list>
 -->     
      <ion-list mode="ios">
       <ion-list-header>
      <ion-label>SONSTIGES</ion-label>
      </ion-list-header>
	<ion-item button @click="() => router.push('tab3/feedback')" detail>
           <ion-label>Feedback</ion-label>
	</ion-item>
	<ion-item button @click="() => router.push('tab3/hilfe')" detail>
           <ion-label>Hilfe</ion-label>
	</ion-item>
	<ion-item button @click="() => router.push('tab3/info')" detail>
           <ion-label>Info</ion-label>
	</ion-item>
</ion-list>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,  IonItem, 
         IonLabel, IonList, IonListHeader } from
'@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
//import { Storage } from '@ionic/storage';
//import { dicOptions } from '@/composables/dicOptions';

//const store = new Storage({name: 'dicstorage'});
//const { setOptions, loadOptions } = dicOptions();

export default defineComponent({
  name: 'Tab3',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonItem, 
    IonLabel,  IonList, IonListHeader },
    setup() {
	//loadOptions();
	const router = useRouter();
	//const { setOptions } = dicOptions();
	return { router };
    },
    async created() {
       //await store.create();
 //      await getOptions('suchmodus').then(res => this.suchmodus = res.value);
  },

    data() {
       //return { suchmodus: ''}
    },
 /*   watch: {
       suchmodus: function()  {
           setTimeout(async () => {
             await getOptions('suchmodus').then(res => this.suchmodus = res.value);
             console.log("timeout2");
           }, 2000); //https://stackoverflow.com/a/48014125
       },
    },*/
/*    ionViewWillEnter() {
	async () => { await getOptions('suchmodus').then(res => this.suchmodus = res.value);}
    console.log('Tab3 page did enter');
  },*/

//watch: {suchmodus: function() {this.suchmodus}}
})
</script>
